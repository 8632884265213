import React from 'react';
import { Routes as NewSwitcherReplacement, Route } from 'react-router-dom';
import {
  Home,
  About,
  // Earrings, Pendants, Sets
} from "../pages";

const Routes = () => {
  return (
    <NewSwitcherReplacement>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      {/* <Route path="/earrings" element={<Earrings />} />
      <Route path="/pendants" element={<Pendants />} />
      <Route path="/sets" element={<Sets />} /> */}
    </NewSwitcherReplacement>
  );
}

export default Routes;
