import React from 'react'

const About = () => {
  return (
    <div className="container mx-auto p-4 mt-16">
      <h1 className='font-bold text-2xl mb-6'>
        Who we are
      </h1>
      <p className='mb-4'>
        Welcome to ABBA Jewelers, a family-owned business that has been cherishing the art of jewelry making since 2010. Rooted in North Carolina with proud Cuban heritage, our journey in crafting high-end jewelry, including bespoke engagement rings and wedding bands, is fueled by a deep love for both the artistry and science of our trade.
      </p>
      <p className='mb-4'>
        At ABBA Jewelers, we're not just jewelers; we're passionate artisans committed to ethical practices. Owning the revered ABBA brand, we pledge to create pure, ethical jewelry using the latest technologies, ensuring no gemstone in our collection is tainted by unethical conflicts.
      </p>
      <p className='mb-4'>
        Our dedication extends beyond craftsmanship to our clients' happiness. We offer 24/7 support, ensuring every interaction with ABBA Jewelers is as memorable and special as our creations. Join us in celebrating a legacy where every piece tells a story of love, ethics, and unparalleled craftsmanship.
      </p>
      {/* Add your Hero section here */}
    </div>
  )
}

export default About