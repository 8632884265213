import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from "./routes/Routes";
import Navbar from "./composition/Navbar";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        {/* Main Content */}
        <main className="flex-grow">
          <Routes />
        </main>

        {/* Footer */}
        <footer className="bg-slate-100 text-slate-950 px-4 py-24">
          <div className="container mx-auto flex justify-between">
            <div className='w-8/12'>
              <p className='font-bold'>
                Stay Connected with ABBA JEWELERS
              </p>

              <p>
                Follow us on social media for the latest updates and exclusive offers.
                For inquiries, contact us at designs@abbajewelers.com or visit our kiosk at
                Monroe Crossing Mall.
              </p>
            </div>

            <p className='w-1/2 text-right'>
              © 2024, ABBA JEWELERS. All Rights Reserved.
            </p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
