import React, { useEffect, useRef, useState } from 'react';
import DiamondsImage from "../assets/images/Diamonds.png";
import DiamondImage from "../assets/images/diamond.png";
import GemsImage from "../assets/images/gemstones.png";

const Home = () => {
  const videoRef = useRef(null);


 
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.7;
    }
  }, []);

  return (
    <div className="container mx-auto mb-32">
      {/* <div className='w-full flex justify-center mt-12 text-2xl relative z-10'>
        <div className='flex flex-col items-center'>
          <h1 className='bold'>All pieces Manufactured in the United States</h1>
          <p className='text-sm'>All pieces are available in all this Precious Metals alloys:</p>
        </div>
      </div> */}

      
      <div className="w-8/12 mx-auto flex flex-col justify-center items-center h-96 mb-24">
        <h1 className='bold text-6xl mt-32'>ABBA JEWELERS</h1>
        <h6 className='bold text-3xl mt-4'>
          Discover the Art of Fine Jewelry
        </h6>
        <p className='mt-6 text-center'>
          Celebrate craftsmanship and timeless style with our exquisite
          collection of gold and silver jewelry. From bespoke engagement
          rings to unique gold grillz, our pieces are crafted with precision
          and passion. Located conveniently at the Monroe Crossing Mall,
          we bring luxury and quality to every detail.
        </p>
        {/* <video ref={videoRef} autoPlay loop muted className='w-1/2 -mx-9 -mt-12 md:-mt-28'>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        {/* <video ref={videoRef} autoPlay loop muted className='w-1/2 -mx-9 -mt-12 md:-mt-28'>
          <source src={videoSrcR} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      </div>
      {/* Add your Hero section here */}

        <div className='flex flex-col md:flex-row md:justify-around w-100'>
          <div className='w-3/12 mb-4 md:mt-6'>
            <div className='text-center rounded-full bg-slate-900 text-white py-2 px-4 mr-2 mb-3'>
              Home Manufacture
            </div>
            <h3 className='font-bold text-lg'>Crafted Locally, Valued Globally</h3>
            <p>
              Every piece of jewelry at ABBA JEWELERS is meticulously handcrafted at our workshop. We specialize in custom designs, offering everything from personalized name plates and engravings to exclusive gold jewelry. Trust us to create pieces that tell your story.
            </p>
          </div>
          <div className='w-3/12 mb-4 md:mt-6'>
            <div className='text-center rounded-full bg-slate-900 text-white py-2 px-4 mr-2 mb-3'>
              Quality First
            </div>
            <h3 className='font-bold text-lg'>Uncompromising Quality with Every Piece</h3>
            <p>
              We prioritize quality above all, ensuring each item meets stringent standards. Our selection of materials, from refined gold to ethically sourced diamonds, guarantees durability and brilliance. Experience jewelry designed to last a lifetime.
            </p>
          </div>
          <div className='w-3/12 mb-4 md:mt-6'>
            <div className='text-center rounded-full bg-slate-900 text-white py-2 px-4 mr-2 mb-3'>
              Full Supoport
            </div>
            <h3 className='font-bold text-lg'>Dedicated Support for Every Customer</h3>
            <p>
              Our commitment extends beyond the sale. Whether you need advice on selecting the perfect gift, assistance with jewelry repairs, or maintenance tips, our expert team is here to provide full support. Visit us for a consultation, or reach out via our website.
            </p>
          </div>
        </div>


    </div>
  );
}

export default Home;
