import React from 'react'
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <header className="bg-slate-100 text-slate-950 p-4 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <div className='flex flex-col items-center'>
          <h1 className="text-xl font-bold">ABBA JEWELERS LLC</h1>
          <h6 className="text-sm">Fine Jewelry Made In USA</h6>
        </div>
        <nav>
          <Link to="/" className="text-slate-950 px-2">Home</Link>
          <Link to="/about" className="text-slate-950 px-2">About</Link>
          {/* <Link to="/earrings" className="text-slate-950 px-2">Earrings</Link>
          <Link to="/pendants" className="text-slate-950 px-2">Pendants</Link>
          <Link to="/sets" className="text-slate-950 px-2">Sets</Link> */}
        </nav>
      </div>
    </header>
  )
}

export default Navbar
